import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const levelSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        updateStudent(state, action) {
            const { payload } = action;
            // Преобразуем все даты в сериализуемый формат
            let newPayload = {...payload}
            if (payload.materials) {
                payload.materials = payload.materials.map(item => ({
                    ...item,
                    dateDate: item.dateDate ? item.dateDate.toISOString() : null
                }));
            }
            return { ...state, ...newPayload };
        }
    }
});

export const { updateStudent } = levelSlice.actions;
export default levelSlice.reducer;