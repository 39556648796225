import { color_white, color_grey_ultra_light, color_grey_dark, color_transparent } from "../../../constants/colors";

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    modalContainer: {
         position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
         
        backgroundColor: color_white,
        padding: 20,
        borderRadius: 20,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '98%',
        maxWidth: 418,
        height: 'min-content',
        zIndex: 1001,
    },

    modalHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    modalBody: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    modalFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
    },
    closeButton: {
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 12px',
        cursor: 'pointer',
    },
    actionButton: {
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 12px',
        cursor: 'pointer',
    },
    textArea:
    {
        width: '100%',
        height: 124,
        marginBottom: 20,
        padding: 10,
        border: '0px solid #ccc',
        borderRadius: 16,
        fontSize: 14,
        boxSizing: 'border-box',
        resize: 'none',
        backgroundColor: color_grey_ultra_light,
    },
    radioBoxRow: {
        display: 'flex',  
        alignItems: 'center',
        marginTop: 8,
        gap: 10,
    },
    timeSelect: {
        //width: 80,
        height: 50,
        backgroundColor: color_grey_ultra_light,
        color: color_grey_dark,
        borderRadius: 10,
        borderColor: color_transparent,
        padding: 10,
        //margin: 8,
    },

};

export default styles;