const weekDayToNameMap = {
    1: {
        full: "Понедельник",
        short: "ПН"
    },
    2: {
        full: "Вторник",
        short: "ВТ"
    },
    3: {
        full: "Среда",
        short: "СР"
    },
    4: {
        full: "Четверг",
        short: "ЧТ"
    },
    5: {
        full: "Пятница",
        short: "ПТ"
    },
    6: {
        full: "Суббота",
        short: "СБ"
    },
    7: {
        full: "Воскресенье",
        short: "ВС"
    },
}

export default weekDayToNameMap