function getRussianNumberEnding(number) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return 'ов';
    }

    switch (lastDigit) {
        case 1:
            return '';
        case 2:
        case 3:
        case 4:
            return 'а';
        default:
            return 'ов';
    }
}

export default getRussianNumberEnding;