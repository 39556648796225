export default  function getUserLessonStatus(studentBalance, studentId) {
    let returnVal = "Последний урокк"
    let balance = studentBalance[studentId].balance
    let cost = studentBalance[studentId].cost
    console.log(`studentBalance: id: ${studentId}, balance: ${balance}`)
    if (balance - cost * 2 > 0) {
        returnVal = "Оплачено"
    } else if (balance - cost <= 0) {
        returnVal = "Не оплачено"
    } else {
        returnVal = "Последний урок"
    }
    studentBalance[studentId].balance = studentBalance[studentId].balance - cost
    return returnVal
}