import { useCallback } from "react";
import {OnestNormalDefault, OnestNormalSmall} from "../../styled/TextComponents";
import {FormInput} from "./Styled";
import InputMask from "react-input-mask";


export const FormPhoneInput = ({
    placeholder = "+7 (___) ___ __ __",
    value,
    title,
    defaultValue,
    register,
    onChange,
    required = false,
    inputBackgroundColor,
}) => {

    
    const memoizedOnChange = useCallback((e) => {
        onChange(e);
    }, [onChange]);
    
    return (
        <OnestNormalDefault weight={"normal"}>
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 10,
                    fontSize: 14
                }}
            >
                <OnestNormalSmall>{title}</OnestNormalSmall>
                <OnestNormalDefault>
                    <InputMask
                        mask="+7 (999) 999 99 99"
                        value={value}
                        defaultValue={defaultValue}
                        {...register}
                        required={required}
                        placeholder={placeholder || "+7 (999) 999 99 99"}
                        style={{
                            width: "350px",
                            height: "50px",
                            borderRadius: "5px",
                            border: "1px solid transparent",
                            backgroundColor: inputBackgroundColor? inputBackgroundColor : "#F5F5F5",
                            outline: "none",
                        }}
                        onChange={memoizedOnChange}
                    />
                </OnestNormalDefault>
            </div>
        </OnestNormalDefault>
    );
};
