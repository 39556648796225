import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentId: null,
    lessonPrice: 0,
    balance: 0,
    age: 0,
    image: '',
    payedLessons: 0,
    name: '',
    englishLevel: 1,
    additionalInfo: '',
    lessonsSchedule: [],
    lessonsProgram: []
};

const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        updateStudent(state, action) {
            const { payload } = action;
            // Преобразуем все даты в сериализуемый формат
            let newPayload = {...payload}
            if (payload.lessonsSchedule) {
                payload.lessonsSchedule = payload.lessonsSchedule.map(item => ({
                    ...item,
                    dateDate: item.dateDate ? item.dateDate.toISOString() : null
                }));
            }
            return { ...state, ...newPayload };
        }
    }
});

export const { updateStudent } = studentSlice.actions;
export default studentSlice.reducer;