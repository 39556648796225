export const englishLevels = ["Pre-k", "Juniors", "Starters", "Movers", "Flyers", "Chat Masters", "Global Communicator"]

export const englishLevelsObject = englishLevels.reduce((obj, level, index) => {
        obj[index] = level;
        return obj;
    }, {});


export const englishLevelsObjectRevers = englishLevels.reduce((obj, level, index) => {
        obj[level] = index;
        return obj;
    }, {});

let englishLevelsObjectArray=[]
englishLevels.map((level, index) => 
    englishLevelsObjectArray = [...englishLevelsObjectArray, {index: index, name: level}]
   );
export { englishLevelsObjectArray }
