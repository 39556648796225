import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation, useBlocker } from "react-router-dom";

import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { styles } from "./styles"
import { OnestBoldSmall, OnestNormalDefault, OnestNormalSmall, OnestSemiBoldSmall } from "../../../styled/TextComponents";
import { Button } from "../../../controls/Button/Button";
import { color_grey_light, color_grey_ultra_light, color_red_default, color_white } from "../../../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../Config";
import axios from "axios";
import { updateStudent } from "../../../../store/StudentSlice";
import { englishLevelsObjectArray } from "../../../../constants/levels";
import Config from "../../../../Config";

export const TextInput = ({
    multiline = false,
    width,
    height,
    title,
    placeholder = null,
    inputKey,
    defaultValue = null,
    inputBackgroundColor = null,
    onChange,
    type = "text",
}) => {
    let attrObj = {};
    const formContext = useFormContext();
    if (formContext) {
        const { register } = formContext;
        if (inputKey && register) { attrObj = { ...register(inputKey) } }
    }


    const memoizedOnChange = useCallback((e) => {
        if (typeof onChange === "function") {
            onChange(e);
        }
    }, [onChange]);


    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestSemiBoldSmall>{title}</OnestSemiBoldSmall>
            {multiline === false ?
                <input
                    type={type}
                    min="0"
                    defaultValue={defaultValue}
                    {...attrObj}
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        backgroundColor: inputBackgroundColor,
                        // paddingLeft: 1
                    }}
                    onChange={memoizedOnChange}

                />
                :
                <textarea
                    defaultValue={defaultValue}
                    {...attrObj}
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        resize: "none",
                        backgroundColor: inputBackgroundColor
                    }}
                    onChange={memoizedOnChange}
                />
            }
        </div>
    )
}


const LevelSelectorButtons = ({ onChange }) => {
    //const englishLevels = ["Pre-k", "Beginner", "Elementary", "Pre-intermediate", "Intermediate", "Upper-intermediate", "Advanced"];
    const student = useSelector(state => state.student);
    const dispatch = useDispatch();
    const [selectedLevel, setSelectedLevel] = useState(+student.englishLevel);

    const handleLevelChange = useCallback((level) => {
        
        
        setSelectedLevel(level);
        if (typeof onChange === "function") {
            onChange(level);
        }
        console.log("level", level)
        dispatch(updateStudent({ englishLevel: level, lessonsProgram: [] }));
        // дописать, чтобы сразу показывала программу, если есть
    }, [selectedLevel]);

    useEffect(() => {
        setSelectedLevel(+student.englishLevel);
    }, [student.englishLevel]);

    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestBoldSmall>Программа</OnestBoldSmall>
            <div style={{ ...styles.levelSelectorContainer }}>
                {englishLevelsObjectArray.map(el => (
                    <div key={el.index}>


                        <label
                            style={{
                                ...styles.levelSelectorButton,
                                ...(+selectedLevel === +el?.index && styles.levelSelectorButtonActive)
                            }}

                            onClick={() => handleLevelChange(el.index)}
                        >
                            <OnestNormalSmall>{el.name}</OnestNormalSmall>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const StudentProfileInfoEdit = ({ levels, image, addCashImage }) => {
    const student = useSelector(state => state.student);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm()

    async function studentDataUpdateSubmit(data) {
        setIsSaveButtonDisabled(true)
        console.log(data, student)
        if (student.studentId === -1) {
            axios.post(config.BACKEND_ADDR + "/students/", {
                name: data.name,
                age: parseInt(data.age),
                english_skill: '' + student.englishLevel,
                comment: data.additionalInfo
            }).then(res => {
                if (image) {
                    axios.patch(config.BACKEND_ADDR + "/students/", {
                        student_id: res.data.id,
                        image: image
                    }).then(() => {
          ;
                        addCashImage('');
                        navigate(`/service/students/${res.data.id}`);
                        window.location.reload();
                    }).catch((err) => {
                        console.log(err);
                        alert("Ошибка обновления данных ученика");
                    })
                } else {              
                    addCashImage('');
                    navigate(`/service/students/${res.data.id}`);
                    window.location.reload();
                }
                navigate(`/service/students/${res.data.id}`)
                window.location.reload();
            }).catch((err) => {
                console.log(err)
                alert("Ошибка обновления данных ученика")
            })
            return
        }
        /* 
                
                axios.get(config.BACKEND_ADDR + `/program/getLevel/`,{
                    params: {level_id: student.englishLevel,}
                    
        
                    student_id: student.studentId,
                    level_name: student.englishLevel
        
                     
                })
                .then((response) => { console.warn(response.data) })
        */

        const filteredLevels = levels.filter(level => +level.name === +student.englishLevel);
        const materials = filteredLevels[0].materials;

        if (materials) {

            let nextLessonProgram = [];
            for (const material of materials) {
                try {



                    const response = await axios.post(config.BACKEND_ADDR + "/program/newStep/", {
                        student_id: student.studentId,
                        level_id: student.englishLevel,
                        material_id: material.id,
                    });

                    let createdAt = new Date(response.data.created_at).getTime();
                    let updatedAt = new Date(response.data.updated_at).getTime();
                    let deletedAt = response.data.deleted_at ? new Date(response.data.deleted_at).getTime() : null;

                    const newData = {
                        ...response.data,
                        created_at: createdAt,
                        updated_at: updatedAt,
                        deleted_at: deletedAt
                    };

                    nextLessonProgram = [...nextLessonProgram, newData]; 
                    
                    dispatch(updateStudent({
                        lessonsProgram: nextLessonProgram
                    }));

                } catch (error) {
                    console.error(error);
                }
            }

           

        }

        axios.patch(config.BACKEND_ADDR + "/students/", {
            student_id: student.studentId,
            name: data.name,
            age: parseInt(data.age),
            english_skill: '' + student.englishLevel,
            comment: data.additionalInfo,
            teacher_id: user.id,
            balance: student.balance
        }).then(() => {
            dispatch(updateStudent({
                studentId: student.studentId,
                name: data.name,
                age: parseInt(data.age),
                englishLevel: data.level || student.englishLevel,
                additionalInfo: data.additionalInfo,
                balance: student.balance
            }))
        }).catch((err) => {
            console.log(err)
            alert("Ошибка обновления данных ученика")
        })
    }

        // Обработчик события покидания страницы
        useEffect(() => {
            const handleBeforeUnload = (e) => {
                if (!isSaveButtonDisabled) {
                    e.preventDefault();
                    e.returnValue = ""; // Показывает стандартное предупреждение браузера
                }
            };
    
            window.addEventListener("beforeunload", handleBeforeUnload);
    
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }, [isSaveButtonDisabled]);

            // Обработчик для предупреждения при навигации внутри приложения
        useEffect(() => {
            const handleRouteChange = (e) => {
                if (!isSaveButtonDisabled) {
                    const confirmLeave = window.confirm("У вас есть несохраненные изменения. Хотите остаться на  странице?");
                    if (!confirmLeave) {
                        e.preventDefault();
                    }
                }
            };

            window.addEventListener("popstate", handleRouteChange);

            return () => {
                window.removeEventListener("popstate", handleRouteChange);
            };
        }, [isSaveButtonDisabled]);

        useBlocker(() => {
            if (!isSaveButtonDisabled) {
                const confirmLeave = window.confirm("У вас есть несохраненные изменения. Хотите остаться на странице?");
                return confirmLeave; // Возвращает true, если пользователь подтвердил переход
            }
            return false; // Разрешает переход, если нет несохраненных изменений
        });

    return (
        <FormProvider {...{ register, watch }}>
            <form
                style={{ ...styles.infoEditForm }}
                onSubmit={handleSubmit((data) => studentDataUpdateSubmit(data))}
            >
                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <TextInput
                        width={205}
                        height={50}
                        title={"Имя и фамилия"}
                        inputKey={"name"}
                        defaultValue={student.name}
                        inputBackgroundColor={color_grey_ultra_light}
                        onChange={() => setIsSaveButtonDisabled(false)}
                    />
                    <TextInput
                        width={205}
                        height={50}
                        title={"Возраст"}
                        type = "number"

                        inputKey={"age"}
                        defaultValue={student.age}
                        inputBackgroundColor={color_grey_ultra_light}
                        onChange={() => setIsSaveButtonDisabled(false)}
                    />
                </div>
                <LevelSelectorButtons
                    onChange={(level) => setIsSaveButtonDisabled(false)}
                    defaultValue={student.englishLevel} />
                <TextInput
                    multiline={true}
                    width={420}
                    height={128}
                    title={"Дополнительная информация"}
                    inputKey={"additionalInfo"}
                    defaultValue={student.additionalInfo}
                    inputBackgroundColor={color_grey_ultra_light}
                    onChange={() => setIsSaveButtonDisabled(false)}

                />
                <div
                    style={{
                        marginTop: 25,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                    <Button
                        width={145}
                        height={40}
                        backgroundColor={isSaveButtonDisabled ? color_grey_light : color_red_default}
                        color={color_white}
                        disabled={isSaveButtonDisabled}
                        type="submit">
                        <OnestNormalDefault>Сохранить</OnestNormalDefault>
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}