import { useCallback } from "react";
import { OnestNormalDefault, OnestNormalSmall } from "../../styled/TextComponents";
import { FormInput } from "./Styled";

export const FormTextInput = ({ placeholder, type, title, valueContainer, onChange }) => {
    return (
        <OnestNormalDefault weight={"normal"}>
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 10,
                    fontSize: 14
                }}
            >
                <OnestNormalSmall>{title}</OnestNormalSmall>
                <OnestNormalDefault>
                    <FormInput
                        placeholder={placeholder}
                        type={type}
                        value={valueContainer}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </OnestNormalDefault>
            </div>
        </OnestNormalDefault>
    );
};

export const NewFormTextInput = ({ 
    placeholder, 
    type, 
    title, 
    defaultValue, 
    register, 
    pattern, 
    inputBackgroundColor = null, 
    required = false, 
    value,
    onChange,
 }) => {

    const memoizedOnChange = useCallback((e) => {
        if (typeof onChange === "function") {
            onChange(e);
        }
    }, [onChange]);

    return (
        <OnestNormalDefault weight={"normal"}>
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 10,
                    fontSize: 14
                }}
            >
                <OnestNormalSmall>{title}</OnestNormalSmall>
                <OnestNormalDefault>
                    <input
                        {...register}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        type={type ? type : "text"}
                        required={required}
                        value={value}
                        style={{
                            width: "350px",
                            height: "50px",
                            borderRadius: "5px",
                            border: "1px solid transparent",
                            backgroundColor: "#F5F5F5",
                            outline: "none",
                        }}
                        onChange={memoizedOnChange}
                    />
                </OnestNormalDefault>
            </div>
        </OnestNormalDefault>
    );
};
