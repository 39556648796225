import PersonalDefaultPage from "../PersonalDefaultPage";
import {
    color_blue_light, color_green_light,
    color_grey_dark,
    color_grey_light,
    color_red_default, color_red_ultra_light,
    color_white, color_yellow_light
} from "../../../constants/colors";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
    OnestBoldDefault,
    OnestNormalBig,
    OnestNormalDefault,
    OnestNormalSmall
} from "../../../components/styled/TextComponents";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { addZeroToTime, weekDayToFullWeekName } from "../JournalPage";

import { updateSchdeule } from "../../../store/ScheduleSlice";
import Config from "../../../Config";
import axios from "axios";
import getUserLessonStatus from "../../../utilities/getUserLessonStatus";
import ModalSimple from "../../../components/blocks/ModalSimple";
import ModalNote from "../../../components/blocks/SchedulePage/ModalNote";
import ModalReschedule from "../../../components/blocks/SchedulePage/ModalReschedule";
import formatDateForFastAPI from "../../../utilities/formatDateForFastAPI";
import { updateStudent } from "../../../store/StudentSlice";
import { updateUser } from "../../../store/UserSlice";
import { updateDayLessonData } from "../../../store/ScheduleDayExtendedSlice";

const GoBackButton = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            width: 35,
            height: 35,
            borderRadius: "50%",
            backgroundColor: color_white,
            color: color_red_default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
        }} onClick={() => navigate(-1)}>
            <BsArrowLeft />
        </div>
    )
}

const ScheduleHeader = () => {
    const dayData = useSelector(state => state.scheduleDayExtended);
    const monthNames = ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"]
    
    if (!dayData?.date) {
        return null;
    }

    const dateObject = new Date(dayData?.date);

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <OnestNormalBig>{dateObject.getDate()} {monthNames[dateObject.getMonth()]}, </OnestNormalBig>
                <OnestNormalBig style={{ color: color_grey_light, marginLeft: 8 }}> {dateObject.getFullYear()}</OnestNormalBig>
            </div>
        </div>
    );

}

const ScheduleDayTimeSlot = ({ datetime, lessons, onOpenModalNote, onOpenModalReschedule }) => {

    const getWithPrefixZeroes = (number, zeroesCount) => number.toString().padStart(zeroesCount, "0")

    function getLessonColor(paymentStatus) {
        if (paymentStatus === "Не оплачено") {
            return color_red_ultra_light
        } else if (paymentStatus === "Последний урок") {
            return color_yellow_light
        } else if (paymentStatus === "Оплачено") {
            return color_green_light
        } else {
            return color_blue_light
        }
    }

    const handleEditLesson = useCallback((lesson) => {

        // Add your edit lesson logic here
        onOpenModalReschedule(lesson);
    }, [onOpenModalReschedule]);

    const handleOpenNote = useCallback((lesson) => {

        onOpenModalNote(lesson);
    }, [onOpenModalNote]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5
            }}>
                <OnestNormalSmall style={{ color: color_grey_dark }}>
                    {getWithPrefixZeroes(datetime.getHours(), 2) + ":" + getWithPrefixZeroes(datetime.getMinutes(), 2)}
                </OnestNormalSmall>
                <div style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: color_grey_dark
                }} />
            </div>
            <div style={{
                height: 50,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {lessons?.map((lesson) => {
                    let lessonStatusColor = getLessonColor(lesson.paymentStatus);
                    return (
                        <div style={{
                            width: "90%",
                            borderRadius: 10,
                            backgroundColor: lessonStatusColor,
                            height: "80%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 10,
                            gap: 10
                        }}>
                            <OnestBoldDefault>{lesson.timeSlot}</OnestBoldDefault>
                            <OnestNormalDefault>{lesson.studentName} {lesson.studentLevel}</OnestNormalDefault>

                            <div style={{
                                backgroundColor: color_white,
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                                boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
                                padding: 10,
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                                transform: "scaleY(1)",
                                transformOrigin: "top",
                                transition: "transform 0.3s ease"
                            }}>
                                <div
                                    style={{
                                        color: color_red_default,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => handleEditLesson(lesson)}
                                >
                                    Изменить урок
                                </div>
                                <div
                                    style={{
                                        color: color_red_default,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => handleOpenNote(lesson)}
                                >
                                    Открыть заметку
                                </div>
                            </div>
                        </div>
                    )

                })}
            </div>

        </div>
    )
}

export const ScheduleDayExtended = () => {
    const dispatch = useDispatch();
    const schedule = useSelector(state => state.schedule);

    const student = useSelector(state => state.student);
    const user = useSelector(state => state.user);

    const dayData = useSelector(state => state.scheduleDayExtended);


    const timeSlots = generateTimeSlots(dayData?.date);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowModalNote, setIsShowModalNote] = useState(false);
    const [isShowModalReschedule, setIsShowModalReschedule] = useState(false);
    const [lessonForModal, setLessonForModal] = useState({});
    const [modalSuccessText, setModalSuccessText] = useState("");
    const [modalErrorText, setModalErrorText] = useState("");

    const [dailyLessons, setDailyLessons] = useState([]);


    async function loadData() {
        let response = await axios.get(Config.BACKEND_ADDR + "/lessons/")
        let lessonsData = response.data
        let uniqueStudents = lessonsData
            .map(el => el.student_id)
            .filter((value, index, array) => array.indexOf(value) === index);
        let studentsBalance = {}
        for (let studentId of uniqueStudents) {
            response = await axios.get(Config.BACKEND_ADDR + `/students/${studentId}`)
            studentsBalance[studentId] = { balance: response.data.balance, cost: response.data.lesson_cost }
        }

        let newLessonMap =  lessonsData.map(el => {
            let startTime = new Date(el.start_time)
            let endTime = new Date(el.end_time)
            return {
                id: el.id,
                studentName: el.student_name,
                studentId: el.student_id,
                dateDate: startTime,
                timeSlot:

                    `${addZeroToTime(startTime.getHours())}:${addZeroToTime(startTime.getMinutes())} - ` +
                    `${addZeroToTime(endTime.getHours())}:${addZeroToTime(endTime.getMinutes())}`,
                paymentStatus: getUserLessonStatus(studentsBalance, el.student_id),
                weekDay: weekDayToFullWeekName(el.weekday).short,
                stunedLevel: el.student_level,
                note: el.note,
                cost: studentsBalance[el.student_id].cost,
            }
        })

        await dispatch(updateSchdeule(newLessonMap))

        const lessons = newLessonMap.filter(lesson => {
            const lessonDate = new Date(lesson?.dateDate);
            return lessonDate?.getDate() === dayData?.date?.getDate();
        });
        dispatch(updateDayLessonData({ date: dayData?.date, lessons: lessons }))
        // dispatch(updateSchdeule(getWeekLessonsFromApi(scheduleWeekRange)))
    }


    function generateTimeSlots(date) {
        let startDate = new Date(date);
        var timeSlots = [];

        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        for (let i = 0; i <= 24 - 1; i += 1) {
            timeSlots.push(new Date(startDate));
            startDate.setMinutes(startDate.getMinutes() + 60);
        }
        return timeSlots
    }

    const memoOpenModalNote = useCallback((lesson) => {
        setLessonForModal(lesson);
        setIsShowModalNote(true);
    }, []);

    const memoOpenModalReschedule = useCallback((lesson) => {
        setLessonForModal(lesson);
        setIsShowModalReschedule(true);
    }, []);

    const memoCloseModalNote = useCallback(() => {
        setLessonForModal({});
        setIsShowModalNote(false);
    }, []);

    const memoSaveNote = useCallback(async (comment, commentOption, lessonData) => {

        const updateLesson = async (lessonIdForUpd) => {
            try {
                let updRes = await axios.patch(Config.BACKEND_ADDR + `/lessons/note/`, {
                    lesson_id: lessonIdForUpd,
                    note: comment,
                })
                return updRes.data;

            } catch (error) {
                return error;

            }
        }

        const successAction = async () => {
            setLessonForModal({});
            setIsShowModalNote(false);
            await loadData()
            setModalSuccessText("Заметка добавлена");
        }


        if (commentOption === "lesson") {
            try {
                let resUpd = await updateLesson(lessonData.id);
                if (resUpd.status === 200) {
                    await successAction();
                } else {
                    console.error("Error updating lesson:", resUpd.data);
                    setModalErrorText("Ошибка обновления урока");
                }

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        } else {
            let numberDays = (commentOption === 'week') ? 7 : 31;

            let lessonsForUpdate = schedule.filter(el => {
                const lessonDate = el.dateDate;
                const startDate = new Date(lessonData.dateDate);
                const endDate = new Date(lessonData.dateDate);
                endDate.setDate(endDate.getDate() + numberDays);
                return lessonDate >= startDate && lessonDate <= endDate && el.studentId === lessonData.studentId;
            });
            try {
                for (const lesson of lessonsForUpdate) {
                    lesson.note = comment;
                    console.log("lesson", lesson);
                    let resUpd = await updateLesson(lesson.id);
                    if (resUpd.status === 200) {
                        //await successAction();
                    } else {
                        console.error("Error updating lesson:", resUpd.data);
                        setModalErrorText("Ошибка обновления урока");
                    }
                }

                await successAction();

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        }




    }, []);

    const memoSaveDateTime = useCallback(async (dateTime, isDelete, isPaid, lessonData) => {

        const [startHour, startMinute] = lessonData.timeSlot.split(" - ")[0].split(":").map(Number);
        const [endHour, endMinute] = lessonData.timeSlot.split(" - ")[1].split(":").map(Number);

        const intervalMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);
        console.warn(`The interval is ${intervalMinutes} minutes.`);


        const endDateTime = new Date(dateTime);
        endDateTime.setTime(endDateTime.getTime() + intervalMinutes * 60 * 1000);

        const end_time = formatDateForFastAPI(endDateTime);
        const start_time = formatDateForFastAPI(new Date(dateTime));

        const updateLesson = async (lessonIdForUpd, start_time, end_time) => {
            try {
                let updRes = await axios.patch(Config.BACKEND_ADDR + `/lessons/datetime/`, {
                    lesson_id: lessonIdForUpd,
                    start_time: start_time,
                    end_time: end_time,
                })
                return updRes;

            } catch (error) {
                return error;

            }
        }

        const successAction = async (text) => {
            setLessonForModal({});
            setIsShowModalReschedule(false);
            await loadData()
            setModalSuccessText(text ? text : "Изменения сохранены");
        }


        if (isDelete) {
            try {
                let resDelete = await axios.delete(Config.BACKEND_ADDR + `/lessons/${lessonData.id}/`);

                if (resDelete.status === 200) {
                    await successAction("Занятие удалено");
                } else {
                    console.error("Error updating lesson:", resDelete.data);
                    setModalErrorText("Ошибка удаления урока");
                }

                if (isPaid) {
                    let newBalance = +student.balance - +lessonData.cost
                    let resUpdStudent = await axios.patch(Config.BACKEND_ADDR + `/students/balance/${lessonData.studentId}`, {
                        balance: newBalance,
                    })
                    if (resUpdStudent.status === 200) {
                        dispatch(updateStudent({ studentId: lessonData.studentId, balance: newBalance }))
                    } else {
                        console.error("Error updating student balance:", resUpdStudent.data);
                        setModalErrorText("Ошибка обновления баланса ученика");
                    }

                    const newTeacherBalance = +user.balance + +lessonData.cost
                    let resUpdTeacher = await axios.patch(Config.BACKEND_ADDR + `/auth/user/balance/${user.id}`, {
                        balance: newTeacherBalance,
                    })
                    if (resUpdTeacher.status === 200) {
                        dispatch(updateUser({ userId: user.id, balance: newTeacherBalance }))
                    } else {
                        console.error("Error updating teacher balance:", resUpdTeacher.data);
                        setModalErrorText("Ошибка обновления баланса учителя");
                    }

                }

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        } else {
            try {

                let resUpd = await updateLesson(lessonData.id, start_time, end_time);
                if (resUpd.status === 200) {
                    await successAction();
                } else {
                    console.error("Error updating lesson:", resUpd.data);
                    setModalErrorText("Ошибка обновления урока");
                }


            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        }


    }, []);

    const memoCloseModalReschedule = useCallback(() => {
        setLessonForModal({});
        setIsShowModalReschedule(false);
    }, []);

    useEffect(() => {

        let newDailyLessons = [];
        timeSlots.map(slot => {
            var lessons = dayData.lessons.filter(lesson => {
                const lessonTime = new Date(lesson.dateDate);
                return lessonTime.getHours() === slot.getHours();
            });
            newDailyLessons = [...newDailyLessons, ...lessons]
        })

    }, [dayData, timeSlots])

    useEffect(() => {
        setDailyLessons(dayData.lessons)
    }, [dayData])
    
    return (
        <PersonalDefaultPage>
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20
            }}>

                <ModalSimple
                    type={"success"}
                    h2={modalSuccessText}
                    isOpen={modalSuccessText !== ""}
                    onClose={setModalSuccessText}
                ></ModalSimple>
                <ModalNote
                    lessonData={lessonForModal}
                    isOpen={isShowModalNote}
                    onClose={memoCloseModalNote}
                    onSave={memoSaveNote}
                />
                <ModalReschedule
                    lessonData={lessonForModal}
                    isOpen={isShowModalReschedule}
                    onClose={memoCloseModalReschedule}

                    onSave={memoSaveDateTime}
                />
                <GoBackButton />
                <ScheduleHeader />
                <div style={{
                    width: "100%",
                    backgroundColor: color_white,
                    borderRadius: 20,
                    padding: 20,
                    boxSizing: "border-box"

                }}>
                    {timeSlots.map(slot => {
                        var lessons = dailyLessons?.filter(lesson => {
                            const lessonTime = new Date(lesson.dateDate);
                            return lessonTime.getHours() === slot.getHours();
                        });
                        return (
                            <ScheduleDayTimeSlot
                                key={slot.getTime()} // Ensure unique key for each slot
                                datetime={slot}
                                lessons={lessons}
                                onOpenModalNote={memoOpenModalNote}
                                onOpenModalReschedule={memoOpenModalReschedule}
                            />
                        )
                    })}
                </div>
            </div>
        </PersonalDefaultPage>
    )
}