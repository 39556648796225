
import ReactModal from "react-modal";
import { color_black, color_grey_light, color_red_active, color_white } from "../../constants/colors";
import { IoMdCheckmarkCircleOutline, IoMdInformationCircleOutline  } from "react-icons/io";
import { CiCircleAlert, CiCircleInfo} from "react-icons/ci";
import { memo, useCallback } from "react";

const ModalSimple = memo(({ type, h2, isOpen, onClose }) => {

    const handleClose = useCallback(() => {
        onClose("");
    }, [onClose]);

    return (
        <ReactModal
            isOpen={isOpen}
            //onRequestClose={onClose}
            //onAfterOpen={onAfterOpen}
            style={{
                content: {
                    backgroundColor: color_white,
                    borderRadius: "10px",
                    width: "400px",
                    height: "234px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.65)",
                    backdropFilter: "blur(8px)",
                    overflow: "scroll",
                },

            }}
        >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {type === "success" && 
                    <IoMdCheckmarkCircleOutline size={25}/>
                }
                {type === "alert" && 
                    <IoMdInformationCircleOutline size={50}/>
                }
                {type === "error" &&
                    <CiCircleInfo size={50}/>
                }
                <h2 style={{ fontSize: "24px", textAlign: "center", color: color_black, marginTop: 8 }}>{h2}</h2>
            <button
                style={{
                    backgroundColor: color_red_active,
                    color: color_white,
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "50px",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "bold",
                    width: 224,
                    height: 40,
                }}
                onClick={handleClose}
            >
                Закрыть
            </button>
            </div>

        </ReactModal>
    )
})

export default ModalSimple