import React, { useState } from "react";
import styles from "./Styles";
import ReactModal from "react-modal";
import RadioBoxEmpty from "../../controls/check-boxes/RadioBoxEmpty";
import { OnestNormal, OnestNormalDefault } from "../../styled/TextComponents";
import { Button } from "../../controls/Button/Button";
import { 
    color_white, 
    color_grey_ultra_light, 
    color_grey_light, 
    color_red_default 
} from "../../../constants/colors";


const ModalNote = ({isOpen, onClose, onSave, lessonData}) => {
    const [comment, setComment] = useState(lessonData.note || "");
    const [commentOption, setCommentOption] = useState("lesson");
    const [ isButtonDisabled, setIsButtonDisabled ] = useState(true);


    
    //console.warn("lessonData", lessonData);

    const handleCommentSubmit = () => {
        console.log("Comment submitted:", comment);
        onSave(comment, commentOption, lessonData);
        onClose();
    };

    const onAfterOpen = () => {
        console.log("onAfterOpen");
    }

    React.useEffect(() => {
        if (comment.length > 0) setIsButtonDisabled(false);
        else setIsButtonDisabled(true);
    }, [commentOption, comment]);

    React.useEffect(() => {
        setComment(lessonData.note || "");  
    }, [lessonData.note]);

    return (
        
           
            <ReactModal 
                isOpen = {isOpen} 
                onRequestClose = {onClose}
                onAfterOpen={onAfterOpen}
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.65)',
                                    backdropFilter: "blur(8px)",
                                    overflow: "scroll"
                                },
                                content: {
                                    ...styles.modalContainer
                                }
                            }}
                >
                    <h3>Заметка к уроку</h3>
                    <textarea
                        style={{...styles.textArea}}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <div style={{ marginBottom: 20 }}>
                        <label style={{ ...styles.radioBoxRow }}>
                            <RadioBoxEmpty
                                checked={commentOption === 'lesson'}
                                onChange={() => setCommentOption('lesson')}
                            />
                            <OnestNormal>Запомнить заметку только на этот урок</OnestNormal>                            
                        </label>
                        <label style={{ ...styles.radioBoxRow }}>
                            <RadioBoxEmpty
                                checked={commentOption === 'week'}
                                onChange={() => setCommentOption('week')}
                            />
                            <OnestNormal>Запомнить заметку на неделю</OnestNormal>                            
                        </label>
                        <label style={{ ...styles.radioBoxRow }}>
                            <RadioBoxEmpty
                                checked={commentOption === 'month'}
                                onChange={() => setCommentOption('month')}
                            />
                            <OnestNormal>Запомнить заметку на месяц</OnestNormal>                            
                        </label>
                    </div>
                    <Button
                        width={"100%"}
                        height={60}
                        backgroundColor={isButtonDisabled ? color_grey_light : color_red_default}
                        disabled={isButtonDisabled}
                        color={color_white}
                        onClick={handleCommentSubmit}>
                            <OnestNormalDefault>Сохранить</OnestNormalDefault>
                    </Button>
            </ReactModal>
      
    );
};

export default ModalNote;