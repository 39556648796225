const studentColorList = [
        {
            "backgroundColor": "#9FBFFF",
            "color": "#4482FF",
        },
        {
            "backgroundColor": "#FFE791",
            "color": "#CA9E00",
        },
        {
            "backgroundColor": "#9AECC5",
            "color": "#00BE63",
        },
        {
            "backgroundColor": "#FF9FBF",
            "color": "#FF4482",
        },
        {
            "backgroundColor": "#BFAFFF",
            "color": "#7A44FF",
        },
        {
            "backgroundColor": "#FFD49F",
            "color": "#FF8A44",
        },
        {
            "backgroundColor": "#A3EFFF",
            "color": "#00BFFF",
        },
        {
            "backgroundColor": "#D4FF9F",
            "color": "#8AFF44",
        },
        {
            "backgroundColor": "#FF9FEF",
            "color": "#FF44C4",
        },
        {
            "backgroundColor": "#C6C6C6",
            "color": "#7A7A7A",
        }
    ]

export default studentColorList;