import React from "react";
import {ModalDefault} from "../../../containers/ModalDefault";
import {ProfileImageUpdating} from "../ImageUpdating/profileImageUpdating/ProfileImageUpdating";
import {ProfileDataUpdatingBlock} from "../dataUpdating/ProfileDataUpdatingBlock";
import {styles} from "./Styles";

export const ProfileUpdatingModal = ({isOpen, onRequestClose}) => {

    return (
        <ModalDefault isOpen={isOpen} onRequestClose={()=>onRequestClose()}>
            <div style={{
                ...styles.modalContainer
            }}>
                <ProfileImageUpdating/>
                <ProfileDataUpdatingBlock 
                    onRequestClose={()=>onRequestClose()}/>
            </div>
        </ModalDefault>
    )
}