import React, { useState, useCallback, useEffect } from "react"; 
import PersonalDefaultPage from "./PersonalDefaultPage";
import { PageTitle } from "../../components/text/PageTitle";
import { TextInput } from "../../components/blocks/studentProfile/studentProfileInfo/StudentProfileInfoEdit";
import { Button } from "../../components/controls/Button/Button";
import { OnestNormalDefault } from "../../components/styled/TextComponents";
import { useDispatch, useSelector } from "react-redux";
import { BsDash, BsPlus } from "react-icons/bs";
import Config from "../../Config";
import axios from "axios";

import { changeActive } from "../../store/FaqSlice";
import { FormPhoneInput } from "../../components/inputs/FormTextInput/FormPhoneInput";

import {
    color_grey_dark,
    color_grey_light,
    color_red_default,
    color_transparent,
    color_white
} from "../../constants/colors";

const FaqQuestion = ({ question }) => {
    const dispatch = useDispatch();

    const FaqQuestionClickButton = () => {
        if (question.active) {
            dispatch(changeActive(null));
        } else {
            dispatch(changeActive(question.id));
        }
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 10
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <div style={{ width: 439 }}>
                    <OnestNormalDefault>{question.question}</OnestNormalDefault>
                </div>
                <div style={{
                    width: 25,
                    height: 25,
                    borderRadius: "50%",
                    backgroundColor: color_red_default,
                    color: color_white,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                    onClick={FaqQuestionClickButton}
                >
                    {question.active ? <BsDash /> : <BsPlus />}
                </div>
            </div>
            {question.active &&
                <div>
                    <OnestNormalDefault style={{ color: color_grey_dark }}>{question.answer}</OnestNormalDefault>
                </div>
            }
        </div>
    );
};

const SupportPage = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state=>state.user);
    const faqData = useSelector(state => state.faq);
    

    // Разделение состояния на отдельные поля
    const [theme, setTheme] = useState("");
    const [question, setQuestion] = useState("");
    const [email, setEmail] = useState(userData?.email || "");
    const [phone, setPhone] = useState(userData?.phoneNumber || "");
    const [isFormValid, setIsFormValid] = useState(false);

    const handleThemeChange = useCallback((e) => setTheme(e.target.value), []);
    const handleQuestionChange = useCallback((e) => setQuestion(e.target.value), []);
    const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);
    const handlePhoneChange = useCallback((e) => setPhone(e.target.value), []);

    const sendSupportRequest = useCallback(() => {
        const formData = { theme, question, email, phone };
        axios.post(Config.BACKEND_ADDR + "/support/new_request", formData)
            .then(response => {
                if (response.status === 200) {
                    alert("Мы получили ваш запрос и ответим в ближайшее время");
                    setTheme('');
                    setQuestion('');
                } else {
                    alert("Ошибка при обработке запроса сервером");
                }
            }).catch(() => {
                alert("Ошибка при отправке запроса на сервер");
            });
    }, [theme, question, email, phone]);

    useEffect(() => {
        setIsFormValid(
            theme.trim() !== "" &&
            question.trim() !== "" &&
            email.trim() !== "" &&
            phone.trim() !== ""
        );
    }, [theme, question, email, phone]);

    return (
        <PersonalDefaultPage>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 551
            }}>
                <PageTitle title={"Новое обращение"} />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20
                }}>
                    <TextInput
                        title={"Тема"}
                        placeholder={"Введите тему сообщения"}
                        inputKey={"theme"}
                        height={48}
                        width={520}
                        multiline={false}
                        inputBackgroundColor={color_white}
                        value={theme}
                        onChange={handleThemeChange}
                    />
                    <TextInput
                        title={"Ваш вопрос"}
                        placeholder={"Сформулируйте ваш вопрос как можно более подробно..."}
                        inputKey={"question"}
                        height={222}
                        width={520}
                        multiline={true}
                        inputBackgroundColor={color_white}
                        value={question}
                        onChange={handleQuestionChange}
                    />
                    <TextInput
                        title={"E-mail"}
                        placeholder={"example@mail.ru"}
                        inputKey={"email"}
                        type={"email"}
                        height={48}
                        width={520}
                        multiline={false}
                        inputBackgroundColor={color_white}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <FormPhoneInput
                        placeholder={userData?.phoneNumber || "+7 (___) ___ __ __"}
                        inputKey={"phone"}
                        type={"tel"}
                        title={"Номер, привязанный к WhatsApp"}
                        value={phone}
                        inputBackgroundColor={color_white}
                        onChange={handlePhoneChange}
                    />
                    <Button
                        outline={false}
                        width={202}
                        height={38}
                        disabled={!isFormValid}
                        backgroundColor={!isFormValid ? color_grey_light : color_red_default}
                        color={color_white}
                        borderColor={color_transparent}
                        fontSize={16}
                        type={"submit"}
                        onClick={sendSupportRequest}
                    >
                        Создать обращение
                    </Button>
                    <OnestNormalDefault style={{ color: color_grey_dark }}>
                        Часы работы службы поддержки с 10:00 до 17:00 по Мск, с понедельника по пятницу. Суббота и
                        воскресенье нерабочие. Среднее время ответа на обращение - в течение одного рабочего дня, не
                        считая дня подачи обращения.
                    </OnestNormalDefault>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <PageTitle title={"FAQ"} />
                <div style={{
                    width: 520,
                    borderRadius: 20,
                    padding: 20,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    gap: 30
                }}>
                    {faqData.map(el =>
                        <FaqQuestion key={el.id} question={el} />
                    )}
                </div>
            </div>
        </PersonalDefaultPage>
    );
};

export default SupportPage;