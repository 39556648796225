import { color_grey_dark, color_transparent } from "../../../constants/colors";
import { FC, ReactNode, useEffect } from "react";
import { useCallback } from "react";


type ButtonProps = {
    children: ReactNode,
    outline: boolean,
    width: number,
    height: number,
    disabled: boolean,
    backgroundColor: string,
    color: string,
    borderColor: string,
    fontSize: number,
    onClick?: () => void,
    type?: string
}

export const Button: FC<ButtonProps> = ({
    children,
    outline,
    width,
    height,
    disabled,
    backgroundColor,
    color,
    borderColor,
    fontSize,
    onClick,
    type = null,
}) => {
    const buttonStyle = {
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        color: color,
        borderColor: borderColor,
        fontSize: fontSize,
        borderRadius: 10,
        boxShadow: "null",
        borderStyle: "solid",
        borderWidth: 1,
        padding: 0,
        cursor: "pointer"
    }
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }else{
            console.log("Button clicked, but no onClick function provided");
        }
    }, [onClick]);

    return (


        <button
            style={buttonStyle}
            disabled={disabled}
            onClick={handleClick}>
            {children}
        </button>
    );

}


